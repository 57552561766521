import React from 'react'
import './NotFound.css'

const NotFound = () => {
  return (
    <section id='notfound' className='container notfound__container'>
        NotFound
    </section>
  )
}

export default NotFound