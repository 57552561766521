import React from 'react'

const Teams = () => {
  return (
    <section id='teams' className='container teams__container'>
      Teams
    </section>
  )
}

export default Teams