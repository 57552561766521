import React from 'react'
import './Schedule.css'
const Schedule = () => {
  return (
    <section id='schedule' className='container schedule__container'>
      Schedule
    </section>
  )
}

export default Schedule